<template>
    <div>
		<b-modal ref="modalSelectCondition" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.print_blank_contract") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('monte.conditions') }} *</label>
					<e-select
						v-model="condition"
						id="contractconfigtypemonte_id"
						track-by="contractconfigtypemonte_id"
						label="formatted_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="conditions"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.formatted_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
					<label for="blankcontract_lang" class="mt-3">{{ $t('monte.langue') }} *</label>
					<e-select
						v-model="lang"
						id="blankcontract_lang"
						label="title"
						track-by="code"
						:options="langs"
						:searchable="true"
						:allow-empty="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.title }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>	

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.imprimer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Contract from "@/mixins/Contract.js"
import Model from "@/mixins/Model.js"

export default {
	name: "ModalSelectCondition",
	mixins: [Contract, Model],
	data () {
		return {
			processing: false,
            condition: null,
            conditions: [],
			lang: {title: this.$t('compte.lang.locales.fr'), code: "fr"},
			langs: [],

            contractconfig_id: null,
            model_id: null,

			labelTitleTemplate: this.getTrad("monte.search_condition"),
			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
		},
		openModal(contractconfig_id, model_id, conditions) {
            this.contractconfig_id = contractconfig_id
            this.model_id = model_id

            conditions.forEach(condition => {
                condition.formatted_label = condition.contractconfigtypemonte_label + ' - ' + condition.contract_type_monte.contracttypemonte_label
            })
            this.conditions = conditions
            
            this.$refs.modalSelectCondition.show()
			this.langs = this.getLocales()
		},
		closeModal() {
			this.$refs.modalSelectCondition.hide()
		},
		async checkForm() {
			if(this.condition && this.condition.contractconfigtypemonte_id) {
				if (this.processing == false) {
					this.processing = true
					await this.printBlankContract(this.contractconfig_id, this.model_id, this.condition.contractconfigtypemonte_id, this.lang.code)
					.then(() => {
						this.$emit('submit')
						this.closeModal()
						this.processing = false
					})
				}
			}
			else {
				this.failureToast("formulaire.erreur_champs_non_remplis")
			}
		}
	}
}
</script>